<template>
  <!-- <ur-page-container ref="page" class="msp" type="subpage" :show-title="false" title="세무팀장 활동확인" :topButton="true" :action-type="userInfo.isSrchOrgCombo === 'Y' ? 'search' : 'subpage'"> -->
  <ur-page-container ref="page" class="msp" type="subpage" :show-title="false" title="세무팀장 활동확인" :topButton="true" :action-type="isManager ? 'search' : 'subpage'">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <mo-button class="btn_popup_back" @click="fn_goMain" />
              <div slot="nav" class="icon-wrapper" @click="fn_goMain">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '세무팀장 활동확인' : '세무팀장 활동확인'}}</div>
              <div slot="action" v-if="isManager" @click="fn_OpenMSPAP106P"><mo-icon>search</mo-icon></div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container alignV="start" componentid="" direction="column" class="pt30"></ur-box-container>             
          </template>              

          <!-- stickey 영역 -->
          <template #sticky>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">
              <ur-box-container alignV="start" componentid="" direction="column" class="con-area row-box mb20">
                <span class="label-title w100px mb0">활동 월</span>
                <mo-month-picker v-model="targetMonth" placeholder="활동 월" :minDate="disabledMonthDate.min" :maxDate='disabledMonthDate.max' @confirmed="fn_selectTaxTmgr" class="full" />
              </ur-box-container>
              <ur-box-container alignV="start" componentid="" direction="column" class="con-area mb0" v-if="isManager">
                <msp-bottom-select :items="taxTmgrList" v-model="pesnNo" @input="fn_search" itemHeight="374" :itemValue="'pesnNo'" :itemText="'pesnNm'" underline placeholder="세무팀장" bottom-title="세무팀장 선택" class="ns-dropdown-sheet" closeBtn scrolling/>
              </ur-box-container>
            </ur-box-container>    
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">
              <ur-box-container alignV="start" componentid="" direction="column" class="pa2024 bd-b-tys10">
                  <ul class="terms-list-area fs14rem crTy-bk7 pb0">
                    <li><span>서명 미완료 시 적용 불가</span></li>
                    <li><span>활동월 말일 입력 마감</span></li>
                    <li><span>동행은 1:1 30분 이상 상담에 한하며, 1대 다수 면담은 불인정</span></li>
                  </ul>
              </ur-box-container> 
            </ur-box-container> 

            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum">총 <strong class="crTy-blue3">{{iCTaxTmgrActHstrVO.length}}</strong> 건</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>

            <template v-if="iCTaxTmgrActHstrVO.length > 0">
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">  
                <mo-list :list-data="iCTaxTmgrActHstrVO">
                  <template #list-item="{item}">
                    <mo-list-item>
                      <div class="list-item__contents">
                        <div class="list-item__contents__title">
                          <span class="name txtSkip" v-if="auth === '1'" @click="fn_Open511Pop(item.cnsltNm, item.cnsltNo)">{{item.cnsltNm + '(' + item.cnsltNo + ')'}}</span>
                          <span class="name txtSkip" v-else @click="fn_Open511Pop(item.pesnNm, item.pesnNo)">{{item.pesnNm + '(' + item.pesnNo + ')'}}</span>
                        </div>
                        <div class="list-item__contents__info" v-if="auth === '2'">
                          <span class="fs14rem mr10">FC</span>
                          <span class="crTy-bk1" @click="fn_Open511Pop(item.cnsltNm, item.cnsltNo)">{{item.cnsltNm}}</span>
                        </div>
                        <div class="list-item__contents__info">
                          <span class="fs14rem mr10">활동일시</span>
                          <span class="crTy-bk1">{{fn_getActYmd(item)}}</span>
                        </div>
                        <div class="list-item__contents__info">
                          <span><span class="fs14rem mr10">분류</span><span class="crTy-bk1">{{item.taxTmgrActScNm}}</span></span>
                          <em>|</em>
                          <span><span class="fs14rem mr10">내용</span><span class="crTy-bk1">{{item.taxTmgrActScCd == '1' ? item.gtghActCntntNm: item.taxTmgrActScCd == '2' ? item.taxTmgrActScNm: ''}}</span></span> 
                        </div>
                        <div class="list-item__contents__info">
                          <span class="fs14rem mr10">고객</span>
                          <span class="crTy-bk1">{{item.custNm}}</span>
                        </div>
                        <div class="list-item__contents__info">
                          <span><span class="fs14rem mr10">FC</span><span :class="item.nwmanSignYn === '1' ? 'crTy-bk1': 'crTy-orange2 fwb'">{{item.nwmanSignYn === '1' ? '서명완료': '서명전'}}</span></span>
                          <em>|</em>
                          <span><span class="fs14rem mr10">관리자</span><span :class="item.mngrSignYn === '1' ? 'crTy-bk1': 'crTy-orange2 fwb'">{{item.mngrSignYn === '1' ? '서명완료': '서명전'}}</span></span> 
                        </div>
                        <div>
                          <ur-box-container alignV="start" componentid="" direction="row" class="insert-area pal0">
                            <mo-button class="ns-btn-round bd-radius-40 mla0 mt20" :disabled="fn_signBtnDisabled(item)"  @click="fn_openBs(item)" v-if="auth == '3' || auth === '2'">{{auth === '3' ? (item.nwmanSignYn === '1' ? '서명취소': '서명'): (item.mngrSignYn === '1' ? '서명취소': '서명')}}</mo-button>
                          </ur-box-container>
                        </div>      
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list> 
              </ur-box-container>
            </template>

            <!-- no-data --> 
            <template v-else>
              <ur-box-container alignV="start" componentid="" direction="column" class="no-data">
                <mo-list-item ref="expItem">
                  <div class="list-item__contents">
                    <div class="list-item__contents__info middle-space">
                      <span class="ns-ftcr-gray"><p class="mt10">활동내역이 없어요</p></span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>
            </template>
           
          </template>          
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>

    <!-- 토스트 -->
    <mo-snackbar ref="stackableSnackbar" stackable :duration="30000" />

    <!-- Popup 알림 -->
    <mo-bottom-sheet ref="nsbottomsheet1" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
      <div class="customer-info-txt txt-center">
        <p class="ment">{{signMsg}}</p>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_close('nsbottomsheet1')" name="취소">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_sign('nsbottomsheet1')" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <MSPAP106P ref="refMSPAP106P" pPage="MSPAP610M" v-show="false"/>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import moment from 'moment'
  import MSPAP106P from '@/ui/ap/MSPAP106P'
  import MSPAP511P from '@/ui/ap/MSPAP511P'
   
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP610M",
    screenId: "MSPAP610M",
    components: {
      MSPAP106P
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created () {
      // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    beforeDestroy () {
      // backKey event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    mounted () {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

      this.hofOrgNo = this.userInfo.onpstHofOrgNo
      this.dofOrgNo = this.userInfo.onpstDofNo,
      this.fofOrgNo = this.userInfo.onpstFofOrgNo

      this.flag = '1'

      if(this.isManager){
        this.$refs.refMSPAP106P.fn_init(this)
      }else{
        this.fn_selectTaxTmgr()
      }
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        flag: '',
        auth: '',
        targetMonth: [String(moment(new Date()).format('YYYY')), String(moment(new Date()).format('MM'))],
        taxTmgrList: [],
        pesnNo: '',
        ICTaxTmgrInfoCmpxVO: {
          inqrYm: '',
          hofOrgNo: '',
          dofOrgNo: '',
          fofOrgNo: '',
          loinId: '',
          inqrScVal: '',
          pesnNo: ''
        },
        iCTaxTmgrActHstrVO: [],
        hofOrgNo: '',
        dofOrgNo: '',
        fofOrgNo: '',
        signMsg: '서명하시겠습니까?'
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      disabledMonthDate () {
        return {
          min: this.$bizUtil.dateDashFormat(moment(new Date(moment().add(-11, 'months'))).format('YYYYMMDD')),
          max: this.$bizUtil.dateDashFormat(moment(new Date()).format('YYYYMMDD'))
        }
      },
      userInfo() {
        return this.getStore('userInfo').getters.getUserInfo
      },
      isManager() {
        return '1' === this.userInfo.userId.substr(0,1)
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*******************************************************************************
      * Function명: fn_goMain
      * 설명: 메인으로 이동
      *******************************************************************************/
      fn_goMain() {
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
        }else{
          this.$router.go(-1)
        }
      },
      fn_getActYmd(item) {
        return this.$bizUtil.dateDashFormat(item.actYmd) + ' ' + item.actStrHm.substr(0,2) + ":" + item.actStrHm.substr(2,2) + '~' + item.actEndHm.substr(0,2) + ":" + item.actEndHm.substr(2,2)
      },
      // 세무팀장콤보 조회
      fn_selectTaxTmgr() {
        let lv_Vm = this
        let trnstId = 'txTSSAP15S1'
        lv_Vm.iCTaxTmgrActHstrVO = []
        lv_Vm.taxTmgrList = []

        console.log('fn_selectTaxTmgr UserInfo >>> ', lv_Vm.userInfo)
        lv_Vm.ICTaxTmgrInfoCmpxVO = {
          inqrYm: lv_Vm.targetMonth[0] + lv_Vm.$bizUtil.lpad(lv_Vm.targetMonth[1], 2, '0'),
          hofOrgNo: lv_Vm.hofOrgNo,
          dofOrgNo: lv_Vm.dofOrgNo,
          fofOrgNo: lv_Vm.fofOrgNo,
          loinId: lv_Vm.userInfo.userId,
          inqrScVal: lv_Vm.flag
        }
        console.log('fn_selectTaxTmgr pParam ==============>>> ', lv_Vm.ICTaxTmgrInfoCmpxVO)
        lv_Vm.post(lv_Vm, lv_Vm.ICTaxTmgrInfoCmpxVO, trnstId, 'S').then(function (response) {
          console.log('response ==============>>> ', response)

          if(response.body != null){
            // 첫 로그인ID가 현재월 세무팀장이면 this.auth = "1", 마파장 및 스탭이면 this.auth = "2", 이외 this.auth = "3" 
            if( lv_Vm.flag === '1') {
              if( response.body.pesnNo != ''){
                lv_Vm.auth = '1'
                lv_Vm.ICTaxTmgrInfoCmpxVO.pesnNo = response.body.pesnNo
              }else {
                if(lv_Vm.isManager){
                  lv_Vm.auth = '2'
                }else{
                  lv_Vm.auth = '3'
                }				
              }
            }
            console.log('lv_Vm.auth >> ', lv_Vm.auth)
            lv_Vm.flag = "0"
            
            // FC
            if(lv_Vm.auth === '3'){
              lv_Vm.fn_search()
            }
            else{
              // 세무팀장
              if(lv_Vm.auth === '1'){
                lv_Vm.taxTmgrList = response.body.iCTaxTmgrInfoVO.filter((item) => {return item.pesnNo == lv_Vm.userInfo.userId})
                lv_Vm.pesnNo = lv_Vm.taxTmgrList[0].pesnNo
              }
              // 지점장이상
              else if(lv_Vm.auth === '2'){
                if(response.body.iCTaxTmgrInfoVO != null){
                  lv_Vm.taxTmgrList = lv_Vm.$bizUtil.cloneDeep(response.body.iCTaxTmgrInfoVO)
                  lv_Vm.taxTmgrList.unshift({'pesnNo': '1', 'pesnNm': '전체'})
                  lv_Vm.pesnNo = '1'
                }
              }

              if(!lv_Vm.$bizUtil.isEmpty(lv_Vm.pesnNo)){
                lv_Vm.fn_search()
              }
            }

          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      fn_search() {
        let lv_Vm = this
        let trnstId = 'txTSSAP15S2'
        lv_Vm.iCTaxTmgrActHstrVO = []

        console.log('fn_search UserInfo >>> ', lv_Vm.userInfo, lv_Vm.pesnNo)
        let pParam = {
          // cnsltNo: userInfo.userId,
          inqrYm: lv_Vm.targetMonth[0] + lv_Vm.$bizUtil.lpad(lv_Vm.targetMonth[1], 2, '0'),
          hofOrgNo: lv_Vm.hofOrgNo,
          dofOrgNo: lv_Vm.dofOrgNo,
          fofOrgNo: lv_Vm.fofOrgNo,
          pesnNo: lv_Vm.pesnNo,
          inqrScCd: '01' //조회구분코드( 01 화면조회 / 02 전지점엑셀조회 )
        }

        if(lv_Vm.auth === '3'){
          trnstId = 'txTSSAP15S3'
          pParam.pesnNo = ''
          pParam.cnsltNo = lv_Vm.userInfo.userId
        }
        console.log('auth >> ', lv_Vm.auth, 'fn_search pParam ==============>>> ', pParam)
        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          console.log('fn_search ==============>>> ', response)

          if ( response.body != null && response.body.iCTaxTmgrActHstrVO != null) {
            lv_Vm.iCTaxTmgrActHstrVO = response.body.iCTaxTmgrActHstrVO


          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
       * Function명 : fn_Open511Pop
       * 설명       : FC문자/전화 팝업 호출
       * 
       ******************************************************************************/
      fn_Open511Pop (cnsltNm, cnsltNo) {
        if(this.auth === '2'){
          this.lv_popup511 = this.$bottomModal.open(MSPAP511P, {
            properties: {
              pCnsltNm: cnsltNm,
              pCnsltNo: cnsltNo,
              FCTelBtn: "전화하기",
              FCSMSBtn: "문자하기"
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(this.lv_popup511)
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.lv_popup511)
              }
            }
          })
        }
      },
      // /******************************************************************************
      //  * Function명 : fn_OpenCustNmUiCard
      //  * 설명       : 고객네임카드
      //  * 
      //  ******************************************************************************/
      // fn_OpenCustNmUiCard (cnsltNm, cnsltNo) {
      //   let nmUiObject = {
      //     chnlCustId : cnsltNo, //고객채널 ID
      //     parentId   : 'MSPCT110M', //화면ID+
      //     cnsltNo : this.userInfo.cnsltNo,
      //     custNm : cnsltNm,
      //     contNo : ''
      //   }

      //   this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
      //     //Close 콜백 처리
      //     return
      //   })
      // },
      fn_OpenMSPAP106P(){
        let lp_Vm = this
        this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
          properties: {
            pPage: 'MSPAP610M',
            pFlag: lp_Vm.flag
          },
          listeners: {
            onPopupSearch: (pData) => {
              console.log('onPopupSearch >> ' , pData)
              // 모달 닫기
              lp_Vm.$bottomModal.close(lp_Vm.popupAP106)

              lp_Vm.fn_srch(pData)
            },
            onPopupClose: () => {
              console.log('onPopupClose')
              // 모달 닫기
              lp_Vm.$bottomModal.close(lp_Vm.popupAP106)
            }
          }
        })
      },
      fn_srch(pData){
        console.log('와랏!!!!')
        this.hofOrgNo = pData.hofOrgNo
        this.dofOrgNo = pData.dofOrgNo
        this.fofOrgNo = pData.fofOrgNo

        this.fn_selectTaxTmgr()
      },
      fn_openBs(item){
        this.selectedItem = item
        this.signMsg = '서명하시겠습니까?'

        // FC
        if(this.auth === '3'){
          if(item.nwmanSignYn === '1'){
            this.signMsg = '서명을 취소 하시겠습니까?'
          }
        }
        // 지점장
        else{
          if(item.mngrSignYn === '1'){
            this.signMsg = '서명을 취소 하시겠습니까?'
          }
        }
        
        this.$refs.nsbottomsheet1.open()
      },
      fn_close(){
        this.selectedItem = null
        this.$refs.nsbottomsheet1.close()
      },
      fn_sign(refStr){
        let lv_Vm = this
        let trnstId = 'txTSSAP15I1'
        let toastMsg = '서명되었습니다.'

        // FC
        if(this.auth === '3'){
          if(this.selectedItem.nwmanSignYn === '1'){
            this.selectedItem.nwmanSignYn = '0'
            toastMsg = '서명이 취소되었습니다.'
          }else{
            this.selectedItem.nwmanSignYn = '1'
          }
        }
        // 지점장
        else{
          if(this.selectedItem.mngrSignYn === '1'){
            this.selectedItem.mngrSignYn = '0'
            toastMsg = '서명이 취소되었습니다.'
          }else{
            this.selectedItem.mngrSignYn = '1'
          }
        }

        let pParam = {
          iCTaxTmgrActHstrVO: [this.selectedItem]
        }

        console.log('fn_fcSign pParam >> ', pParam)
        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          console.log('fn_fcSign ==============>>> ', response)

          lv_Vm.$addSnackbar(toastMsg)

        }).catch(function (error) {
          window.vue.error(error)
        })

        this.fn_close(refStr)
      },
      fn_signBtnDisabled(item){
        if(this.auth === '3'){
          // 강의활동 또는 지점장서명 되어 있으면 신인서명란 비활성화 
          if(item.mngrSignYn === '1' || item.taxTmgrActScCd === '2'){
            return true
          }else{
            return false
          }
        }else{
          // 관리자 - 서명권한
          console.log('this.userInfo.dutyCd >> ', this.userInfo.dutyCd, '    this.userInfo.srvcMngScCd >> ', this.userInfo.srvcMngScCd)
          console.log('item.taxTmgrActScCd >> ', item.taxTmgrActScCd, '   item.nwmanSignYn >> ', item.nwmanSignYn)
          if((this.userInfo.dutyCd === 'B26' || this.userInfo.dutyCd === 'B52' || this.userInfo.dutyCd === 'B53') && (this.userInfo.srvcMngScCd === 'GP' || this.userInfo.srvcMngScCd === 'SA')){
            if(item.taxTmgrActScCd === '1'){
              if(item.nwmanSignYn === '1'){
                return false
              }else{
                return true
              }
            }else{
              return false
            } 
          }else{
            return true
          }
        }
      }

    }
  }
</script>
<style scoped>
</style>